import axios from 'axios';
import appConfig from '../../config.json';
import storage from '../../lib/Storage/storage';

const getHttpHeaders = async (config) => {
  let headers = {
    ...config.headers,
    'Content-Type':
      (config.headers && config.headers['Content-Type']) || 'application/json',
  };

  const accessToken = await storage.getItem('API_ACCESS_TOKEN');

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return headers;
};

const injectSpaceId = async (config, method, contentType) => {
  const spaceId = await storage.getItem('CURRENT_SPACE_ID');

  if (spaceId) {
    if (contentType === 'application/json') {
      let payloadKey;
      if (method === 'GET') {
        payloadKey = 'params';
      } else {
        payloadKey = 'data';
      }

      config[payloadKey] = config[payloadKey] || {};
      config[payloadKey] = {
        ...config[payloadKey],
        spaceId,
      };
    } else {
      const formData = config.data;

      formData.append('spaceId', spaceId);
    }
  }
};

const injectLocale = async (config, method, contentType) => {
  const locale = (await storage.getItem('locale')) || 'en';

  if (contentType === 'application/json') {
    let payloadKey;

    if (method === 'GET') {
      payloadKey = 'params';
    } else {
      payloadKey = 'data';
    }

    config[payloadKey] = config[payloadKey] || {};
    config[payloadKey] = {
      ...config[payloadKey],
      locale,
    };
  } else {
    const formData = config.data;

    formData.append('locale', locale);
  }
};

const apiClientRequest = async (
  path,
  config = {},
  { locale = true, spaceId = true } = {},
) => {
  const { method: httpMethod = 'GET' } = config;

  const method = httpMethod.toLowerCase();
  const url = `${appConfig.apiUrl}${path}`;
  const headers = await getHttpHeaders(config);
  if (locale) {
    await injectLocale(config, httpMethod, headers['Content-Type']);
  }
  if (spaceId) {
    await injectSpaceId(config, httpMethod, headers['Content-Type']);
  }

  try {
    const response = await axios({
      ...config,
      url,
      method,
      headers,
    });

    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    throw err.response;
  }
};

export default apiClientRequest;
