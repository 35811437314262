import apiClientRequest from '../../shared/helpers/api-client-request';

export const apiGetAvailableSpaces = async () =>
  apiClientRequest('/users/spaces');

export const apiGetCurrentUser = () =>
  apiClientRequest('/user-session-info/current-user');

export const apiGetCurrentSpaceQuotaUsage = () =>
  apiClientRequest('/subscription-info/active-space-quota-usage');

export const apiGetCurrentSpaceInformation = () =>
  apiClientRequest('/user-session-info/current-space');

export const apiGetCurrentUserQuotaUsage = () =>
  apiClientRequest('/subscription-info/user-quota-usage');

export const apiGetCurrentUserActiveSubscription = () =>
  apiClientRequest('/subscription-info/active-subscription');

export const apiGetCurrentSpaceActiveSubscription = () =>
  apiClientRequest('/subscription-info/active-space-subscription');

export const apiGetCurrentSubscriberInformation = () =>
  apiClientRequest('/session-subscriber-information');

export const apiGetCurrentSubscriberAddress = () =>
  apiClientRequest('/session-subscriber-information/address');

export const apiGetSubscriberPaymentMethods = async () =>
  apiClientRequest('/billing-overview/payment-methods');

export const apiGetPaymentProcessingStatus = () =>
  apiClientRequest('/subscription-info/payment-processing-status');

export const apiCountSubscriberUsers = async () =>
  apiClientRequest('/subscription-info/users-number');
