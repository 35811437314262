import atob from 'atob';
import storage from '../../lib/Storage/storage';
import pickBy from 'lodash/pickBy';

class UserSession {
  constructor(accessToken, spaceToken) {
    this.init(accessToken, spaceToken);
  }

  init(accessToken, spaceToken) {
    const data = parseJwt(accessToken);
    if (data) {
      this.uid = data.uid;
      this.groups = data.groups;
      this.subscriberId = data.subscriberId;
      this.subscription = data.subscription;
      this.subscriptionPlan = data.subscriptionPlan;
      this.iat = data.iat;
      this.exp = data.exp;
      this.space = parseSpaceToken(spaceToken);
    } else {
      this.anonymous = true;
    }
  }

  static getAnonymousUserSession() {
    return {
      anonymous: true,
    };
  }

  data() {
    return pickBy({
      uid: this.uid,
      groups: this.groups,
      subscriberId: this.subscriberId,
      subscription: this.subscription,
      subscriptionPlan: this.subscriptionPlan,
      space: this.space,
      anonymous: this.anonymous,
      exp: this.exp,
      iat: this.iat,
      spacesAdminOn:
        this.groups?.reduce(
          (map, group) => ({
            ...map,
            [group.spaceId]: group.name === 'ADMIN',
          }),
          {},
        ) || {},
    });
  }

  isAdminOn(space: string) {
    return this.groups.find(
      ({ spaceId, name }) => spaceId === space && name === 'ADMIN',
    );
  }

  static async getUserSession() {
    const token = await storage.getItem('API_ACCESS_TOKEN');
    const spaceToken = await storage.getItem('CURRENT_SPACE_TOKEN');

    const session = new UserSession(token, spaceToken);

    return session.data();
  }

  static async persistUserSession(accessToken) {
    await storage.setItem('API_ACCESS_TOKEN', accessToken);
  }

  static async persistSpaceId(spaceId) {
    await storage.setItem('CURRENT_SPACE_ID', spaceId);
  }

  static async clear() {
    await storage.removeItem('API_ACCESS_TOKEN');
    await storage.removeItem('CURRENT_SPACE_TOKEN');
  }

  static async isUserLoggedIn() {
    const token = await storage.getItem('API_ACCESS_TOKEN');

    return token !== null;
  }
}

export default UserSession;

function parseJwt(accessToken) {
  if (accessToken) {
    const base64Url = accessToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }
  return false;
}

export const parseSpaceToken = (token) => {
  if (token) {
    const decryptedHash = atob(token);
    try {
      return JSON.parse(decryptedHash);
    } catch (err) {
      return null;
    }
  }
  return null;
};
